.payment-details-container {
    margin-top: 2rem;
  
    .back-to-login {
      color: #0a6b89;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  
    .next-button {
      background-color: #0a6b89;
      border: none;
      &:hover {
        background-color: darken(#0a6b89, 10%);
      }
    }
  }
  