

.support-content{
    max-height: calc(100vh - 65px);
}

.nav-tabs .nav-link {
    margin-bottom: 0;
    border: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color:rgba(106, 106, 106, 1);

    &.active {
        color: rgba(16, 24, 40, 1);
        font-weight: 700;
        border-bottom: 2px solid rgba(19, 145, 69, 1);
    }
}
.accordion-button{
    background-color: #fff !important;
    &:focus{
        box-shadow: none;
    }
}
.pointer{
    cursor: pointer;
}