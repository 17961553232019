

.support-content{
    max-height: calc(100vh - 65px);
}

.nav-tabs .nav-link {
    margin-bottom: 0;
    border: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color:rgba(106, 106, 106, 1);

    &.active {
        color: rgba(16, 24, 40, 1);
        font-weight: 700;
        border-bottom: 2px solid rgba(19, 145, 69, 1);
    }
}

.faq-question{
    .accordion-button{
        background-color: #fff !important;
        font-size: 16px;
        font-weight: 600;
        color: rgba(27, 27, 28, 1);
        &:focus{
            box-shadow: none;
        }
        &::after {
            content: "+" !important;
            font-size: 30px;
            font-weight: 400;
             width: 20px; 
            height: 20px;
            margin-left: auto;
            content: "";
             background-image: none; 
             background-repeat: no-repeat;
             background-size: unset;
             transition: none ;
            color: rgb(27, 27, 28);
        }
        &:not(.collapsed)::after {
             background-image: none; 
             transform: none; 
            content: "-" !important;
        }
    }
}