
.input_field {
    border: 1px solid #C9CAD9;
    border-radius: 8px;
    padding: 10px 16px;
    margin: 8px 0;

    &:hover,
    &:focus-visible,
    &:focus,
    &:active {
        box-shadow: none;
        border: 1px solid #C9CAD9;
        outline: unset;
    }
}
.btn-secondary {
    background: rgba(241, 186, 8, 1) !important;
    border: 1px solid rgba(241, 186, 8, 1) !important;
    color: rgba(16, 24, 40, 1);
    // font-weight: 600;
}
.btn.btn-outline {
    border: 1px solid rgb(208, 213, 221);
    background: #fff;
    color: rgb(70, 71, 71);
}
.mnw-125{
    min-width: 125px;
}
.mxw-600{
    max-width: 600px;
}
.react-datepicker-wrapper{
    width: 100%;
}

.eye-icon {
    top: 14px;
    right: 12px;
    height: 15px;
    width: 15px;
    filter: contrast(0);
}
.mxw-50{
    max-width: 50px !important;
}
.mxw-40{
    max-width: 40px !important;
}
.mxw-180{
    max-width: 180px !important;
}