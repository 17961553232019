.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: rgba(16, 24, 40, 1) !important;
    font-weight: 700 !important;
    background-color: rgba(245, 249, 255, 1) !important;
}

.profile-wrapper{
    max-height: calc(100vh - 65px);
}

.profile-wrapper-inner{
    max-height: calc(100% - 40px);
}
@media (max-width: 768px) {
    .mobile-format{
        height: auto !important;
    }
    .mobile-padding{
        padding: 10px 1px 10px 1px !important;
    }
    .mobile-pad{
        padding: 4px !important;
    }
}
