.login-left-side{
    background: rgba(16, 24, 40, 1);
 @media screen and (min-width:992px) {
    max-width: 405px;
    min-width: 405px;
 }
}
.fm-logo{
    width: fit-content;
}
.shield-icon{
    height: 475px;
    width: 100%;
    padding-top: 45px;
    // opacity: 0.4;
    @media screen and (max-width: 767px){
        height: 375px;
    }
}