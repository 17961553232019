/* Full-screen overlay to dim the background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dim background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Make sure it's on top */
  }
  
  /* Shimmer box to show shimmer animation */
  .shimmer-box {
    width: 200px;
    height: 100px;
    background: #f0f0f0;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
  
  /* Shimmer animation */
  .shimmer {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
.fm-spinner {
    background: #183813;
    height: 60px;
    width: 60px;
    animation: rotate-spinner 1s linear infinite;
    filter: drop-shadow(3px 0px 2px #fff);
}

@keyframes rotate-spinner {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}