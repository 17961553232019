.tooltip-inner {
    max-width: fit-content !important; 
    width: inherit !important; 
    padding: 0 16px 0 16px !important;
    background: #ffffff !important;
    color: black !important;
    border-radius: 16px !important;
}
.tooltip{
    border: 1px solid rgba(234, 234, 234, 1) !important;
    border-radius: 12px !important;
}
.tooltip-arrow{
    background-color: #ffffff;
    left: -7px !important;
    &::after, &::before{
        color: #ffffff;
        border: 1px solid rgba(234, 234, 234, 1);
        border-right-color: rgb(234 234 234) !important;
        background-color: transparent !important;
    }
}
.w-12{
    width: 12px !important;
}
.border-grey-custom{
    border-bottom: 2px solid rgba(208, 208, 208, 1) !important;
    display: inline-block !important;
}
.border-orange-custom{
    border-bottom: 2px solid rgba(253, 129, 5, 1) !important;
    display: inline-block !important;
}
.border-green-custom{
    border-bottom: 2px solid rgba(19, 145, 69, 1) !important;
    display: inline-block !important;
}
.tooltip{
    li::marker{
        color: rgba(0, 86, 216, 1);
    }
}
.text-grey-custom{
    color:  rgba(13, 19, 33, 1) !important;
    display: inline-block !important;
}
.text-orange-custom{
    color: rgba(253, 129, 5, 1) !important;
    display: inline-block !important;
}
.text-green-custom{
    color:  rgba(19, 145, 69, 1) !important;
    display: inline-block !important;
}
.text-grey-24{
    color: rgba(24, 24, 24, 1) !important;
}
.text-grey-167{
    color: rgba(167, 167, 167, 1) !important;
}
.form-control.is-invalid, .was-validated .form-control:invalid{
    background-image: none !important;
}
.form-control.is-invalid:focus{
    box-shadow: none !important;
}
.bg-blue{
    background-color: rgba(206, 226, 255, 1) !important;
}
.input_field{
    padding: 10px 34px 10px 16px !important;
    border: 1px solid #C9CAD9 !important;
    border-radius: 8px !important;
    margin: 8px 0 !important;
}