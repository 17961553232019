// styles/Alert.scss
$alert-padding: 1rem;
$alert-border-radius: 0.25rem;
$alert-transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
$alert-transform-distance: -20px;

.alert-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
}

.alert {
  padding: $alert-padding;
  border-radius: $alert-border-radius;
  opacity: 1;
  transition: $alert-transition;
  transform: translateY(0);

  &.fade-in {
    opacity: 1;
    transform: translateY(0);
  }

  &.fade-out {
    opacity: 0;
    transform: translateY($alert-transform-distance);
  }

  &.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }

  &.alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }

  &.alert-warning {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
  }

  &.alert-info {
    background-color: #d1ecf1;
    color: #0c5460;
    border: 1px solid #bee5eb;
  }
}
