@mixin scroll($bgColor, $shadow, $display) {
    &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
        box-shadow: none;
        background-color: $bgColor;
        display: $display;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: transparent;
        box-shadow: $shadow;
    }

    &:hover,
    :focus {
        &::-webkit-scrollbar {
            background-color: rgba(0, 0, 0, 0.12);
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.25);
        }
    }
}

* {
    font-family: "Mulish", sans-serif;
    @include scroll(transparent, none, unset);
}

.show-scroll-bar {
    @include scroll(rgba(0, 0, 0, 0.12), inset 0 0 0 12px rgba(0, 0, 0, 0.25), unset);
}

.hide-scroll-bar {
    @include scroll(transparent, none, none);
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}
.fs-md-24 {
    @media  (min-width: 768px) {
        
        font-size: 24px;
    }
}

.fs-28 {
    font-size: 28px;
}

.fs-30 {
    font-size: 30px;
}
.fs-32 {
    font-size: 32px;
}

.fs-36 {
    font-size: 36px;
}

.fs-46 {
    font-size: 46px;

    @media screen and (max-width:767px) {
        font-size: 36px;
    }
}




.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.decoration-none {
    text-decoration: none;
    list-style: none;
}

.cursor {
    cursor: pointer;
}

.radius-4 {
    border-radius: 4px;
}

.radius-8 {
    border-radius: 8px;
}

.radius-12 {
    border-radius: 12px;
}

.radius-16 {
    border-radius: 16px;
}

.hw-16 {
    height: 16px;
    width: 16px;
}

.hw-20 {
    height: 20px;
    width: 20px;
}

.hw-24 {
    height: 24px;
    width: 24px;
}

.hw-30 {
    height: 30px;
    width: 30px;
}

.hw-32 {
    height: 32px;
    width: 32px;
}

.hw-40 {
    height: 40px;
    width: 40px;
}

.hw-55 {
    height: 55px;
    width: 55px;
}

.hw-60 {
    height: 60px;
    width: 60px;
}

.hw-65 {
    height: 65px;
    width: 65px;
}

.hw-72 {
    height: 72px;
    width: 72px;
}

.hw-80 {
    height: 80px;
    width: 80px;
}

.hw-150 {
    height: 150px;
    width: 150px;
}

.hw-160 {
    height: 165px;
    width: 165px;
}
.black-70{
    color: rgba(70, 71, 71, 1);
}
.black-65{
    color: rgba(65, 65, 65, 1);
}
.black-40{
    color: rgba(40, 40, 40, 1);
}
.black-16{
    color: rgba(16, 24, 40, 1);
}
.black-24{
    color: rgba(24, 24, 24, 1);
}
.grey-106{
    color: rgba(106, 106, 106, 1);
}
.grey-70{
    color: rgba(70, 71, 71, 1);
}
.grey-126{
    color: rgba(126, 126, 126, 1);
}
.grey-106{
    color: rgba(143, 143, 143, 1);
}
.grey-143{
color: rgba(143, 143, 143, 1);
}
.w-fit{
    width: fit-content;
}

.btn-secondary {
    background: rgba(241, 186, 8, 1) !important;
    border: 1px solid rgba(241, 186, 8, 1) !important;
    color: rgba(16, 24, 40, 1) !important;
    // font-weight: 600;
}
.form-control:focus{
    box-shadow: none;
    outline: 0;
}